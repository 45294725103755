import Calendar from "../assets/calendar.png";
import eCommerce from "../assets/e-commerce.png";

export const ProjectList = [
  {
    name: "Calendar Event Web Application",
    image: Calendar,
    skills: "NPM, React JS, PostgreSQL",
    description:
      "The Calendar Events Maintenance web application to create, view, and modify events associated with the AICE degree program at CMKL University.",
  },
  {
    name: "E-commerce Web Application",
    image: eCommerce,
    skills: "React JS, Bootstrap, API-Gateway",
    description:
      "An E-commerce web app that sells different kinds of products, It is a digital platform that allows businesses to sell a variety of goods or services online to customers.",
  },
  // {
  //   name: "Social Media Website",
  //   image: Proj2,
  //   skills: "React JS, Html, Css",
  // },
];
