import React from "react";
import { LinkedIn, Email } from "@material-ui/icons";
import GithubIcon from "@material-ui/icons/GitHub";
import ReactTypingEffect from "react-typing-effect";
import "../styles/Home.css";
import Link from "@mui/material/Link";
import myPic from "../assets/myPic.jpeg";
import DownloadButton from "../helpers/DownloadButton";

function Home() {
  const linkedInLink =
    "https://www.linkedin.com/in/ashyr-magtymgulyyev-42ba16197/";
  const emailLink = "mailto:ashyr.magtymguly@gmail.com";
  const githubLink = "https://github.com/Ash-HubGit";
  return (
    <div className="home">
      <div className="about">
        <img src={myPic} alt="Me@Ash" className="my-pic" />
        <h2>
          <ReactTypingEffect
            text={["Hi, My Name is Ash", "I am a Web Developer"]}
            speed={80}
            eraseDelay={200}
          />
        </h2>
        <div className="prompt">
          <p>A Front-End developer with a passion for learning and creating.</p>
          <Link href={linkedInLink} target="_blank">
            <LinkedIn />
          </Link>
          <Link href={githubLink} target="_blank">
            <GithubIcon />
          </Link>
          <Link href={emailLink} target="_blank">
            <Email />
          </Link>
          <div className="mycv-btn">
            <DownloadButton />
          </div>
        </div>
      </div>
      <div className="skills">
        <h1>Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Front-End Technologies</h2>
            <span>
              JavaScript, HTML/CSS, React, Flutter, NPM, DOM Manipulation, JSON,
              Node.js, PostgreSQL, DBeaver.
            </span>
          </li>
          <li className="item">
            <h2>Front-End Tools</h2>
            <span>
              Material UI, Google Fonts, Bootstrap, TailwindCSS, Styled
              Components
            </span>
          </li>
          <li className="item">
            <h2>Programming Languages</h2>
            <span>Java, JavaScript, Flutter, Python, Visual Basic.</span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
