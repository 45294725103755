import React from "react";
import Button from "@mui/material/Button";

// const url = `http://localhost:3000/myCV.pdf`;

function DownloadButton() {
  // const handleClick = () => {
  //   const url = `http://localhost:3000/Ash'sCV.pdf`; // Replace with your file URL
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "Ash'sCV.pdf"); // Replace with your file name
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleClick = () => {
    fetch(`https://ashdev.info//myCV.pdf`)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        // const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", "Ash's_CV.pdf");
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  return (
    <Button
      variant="contained"
      onClick={() => {
        handleClick();
      }}
      sx={{
        backgroundColor: "white",
        color: "#2C3E50",
        fontWeight: "bold",
        width: "30%",
        postion: "relative",
        bottom: "3rem",
        transition: "transform 0.2s ease-in-out",
        ":hover": {
          backgroundColor: "white",
          color: "#2C3E50",
          transform: "scale(1.2)",
        },
        "@media only screen and (max-width: 600px)": {
          fontSize: "13px",
          width: "60%",
          position: "relative",
          top: "-30px",
          transition: "transform 0.2s ease-in-out",
          ":hover": {
            transform: "scale(1.2)",
          },
        },
      }}
    >
      My CV
    </Button>
  );
}

export default DownloadButton;
